/**
 * 账号
 */
export default class IM_User {
    id: number = 0;
    uid: string = "";
    nick_name: string = "";
    head_pic: string = "";
    sex: number = 0;
    permission: number = 0;
    account: string = "";
    pwd: string = "";
    status: number = 0;
    create_time: string = "";
    last_login_time: string = "";
    phone: string = "";
    /**
     * 主账号UID
     */
    master_uid: string = "";
    /**
     * 是否为主号 1为主号 0不是主号
     */
    is_master: number = 0;
    /**
     * 钱包地址
     */
    wallet_address: string = "";
    birthady: string = "";
    personal_label: string = "";
    personal_signature: string = "";
    /**
     * 最大小程序数量
     */
    max_applet_count: number = 0;
    /**
     * 最大可创币数量
     */
    max_coin_count: number = 0;
    is_online: number = 0;
    offline_time: string = "";
    online_time: string = "";
    nft_star: number = 0;
    /**
     * 作者介绍
     */
    author_introduction: string = "";
    /**
     * 是否有首页铸造权
     */
    home_page_mint_power: number = 0;
    /**
     * 是否已实名 1已实名
     */
    is_real_name: number = 0;
    /**
     * 
     */
    check_in_time: string = "";
    my_superior_uid: string = "";
    invitation_reward: number = 0;
    unlock_time: string = "";
    try_login_num: number = 0;
    pay_pwd: string = "";
    try_pay: number = 0;
    pay_lock_time: string = "";
    miu_is_synchronization: string = "";
    miu_amount: number = 0;
    daily_check_in_day: number = 0;
    last_daily_check_in_time: string = "";
    is_disable: number = 0;
    select_platform_guid: string = "";
    admin_platform_guid: string = "";
    /**
     * 权限标识
     */
    api_authorization_permission_id:number=0;
}