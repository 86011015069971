<template>
  <div class="premission_body">
    <div class="premission_content">
      <img
        class="closebtn"
        src="@/assets/images/NftCasting/block_x.png"
        @click="CloseMe"
      />
      <div class="title">选择权限</div>
      <div class="premission_content_item">
        <template :key="key" v-for="(d, key) in premission_all_data">
          <div v-if="d?.length>0">
            <div class="premission_title">{{ key }}</div>
            <div class="premission_box">
              <div
                :key="i"
                v-for="(v, i) in d"
                :class="['item', CheckIsSelect(v?.id) ? 'active_item' : '']"
                @click="SelectPremission(v?.id)"
              >
                <span>{{ v.sys_permission_group_name }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <el-button type="primary qdbtn" @click="ToSelect()">确定</el-button>
    </div>
  </div>
</template>
<script>
import Tools from "@/ts/Tools";
export default {
  props: {
    select_premission_callback_fun: function (list) {},
    select_premission_multiple: Boolean,
    default_select_premission_list: [], //默认选择的列表
  },
  data() {
    return {
      select_premission_list: [],
      premission_all_data: {},
    };
  },
  methods: {
    CloseMe() {
      this.select_premission_list.Clear();
      this.$parent.show_select_premission_window = false;
    },
    CheckIsSelect(premission_id) {
      return (
        this.select_premission_list.Where((x) => x == premission_id).length > 0
      );
    },
    ToSelect() {
      if (this.select_premission_list.length == 0) {
        Tools.AlertError("至少要选择一个权限!");
        return;
      }
      this.select_premission_callback_fun &&
        this.select_premission_callback_fun(this.select_premission_list);
      this.CloseMe();
    },
    async GetPremissionGroupListAsync() {
      let res = await Tools.GetHtmlAsync("GetPremissionGroupList", {
        only_can_select_to_user: true,
      });
      if (Tools.CheckHtmlIsOK(res, false)) {
        this.premission_all_data = {};
        res.data.forEach((v) => {
          let key = v.premission_tab.tab_name;
          if (!this.premission_all_data[key])
            this.premission_all_data[key] = [];
          this.premission_all_data[key].push(v);
        });
      }
    },
    SelectPremission(premission_id) {
      if (!this.select_premission_multiple) {
        this.select_premission_list.Clear();
      }
      let count = this.select_premission_list.Where(
        (x) => x == premission_id
      ).length;
      if (count > 0) {
        this.select_premission_list.ToRemove(premission_id);
      } else {
        this.select_premission_list.push(premission_id);
      }
    },
  },
  async mounted() {
    await this.GetPremissionGroupListAsync();

    this.select_premission_list.PushAll(this.default_select_premission_list);
  },
};
</script>
<style lang="scss" scoped>
.premission_body {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .premission_content {
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    box-sizing: content-box;
    border: 0px;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    .closebtn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 16px;
    }

    .title {
      text-align: center;
      margin-bottom: 10px;
      font-size: 16px;
    }

    .premission_content_item {
      width: 40vw;
      max-height: 80vh;
      font-size: 12px;
      overflow-y: auto;
      width: 360px;
      height: auto;
    
      .premission_title {
        font-size: 16px;
        margin: 16px 0px 10px 0px;
      }

      .premission_box {
        display: flex;
        flex-wrap: wrap;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.1);
          width: 100px;
          text-align: center;
          margin-right: 1px;
          margin-bottom: 1px;
          box-sizing: content-box;
          height: 20px;
          cursor: pointer;
          color: #333;
          padding: 6px;
          border-radius: 5px;
          margin-right: 10px;
        }

        .active_item {
          background-color: #0066ff !important;
          color: #fff;
        }
      }
    }

    .qdbtn {
      width: 60%;
      margin-top: 20px;
    }
  }
}
</style>