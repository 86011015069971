<template>
  <slot v-if="CheckHavePremissionGroup"> </slot>
</template>
  <script>
import { inject } from "vue";
import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
export default {
  setup() {
    let user_vo = inject("user_vo");
    let premission_list = inject("premission_list");
    return {
      premission_list,
      user_vo,
    };
  },
  props: {
    premission_name: String,
  },
  computed: {
    CheckHavePremissionGroup() {
      if (Tools.IsNull(this.premission_name)) return true;
      if (this.user_vo.api_authorization_permission_id == -1) return true;

      return (
        this.premission_list.Where(
          (x) => x.sys_premission_identification == this.premission_name
        ).length > 0
      );
    },
  },
  data() {
    return {};
  },
  methods: {

  },
  mounted() {},
};
</script>