<template>
  <div class="suspension">
    <div class="bucket_body">
      <div class="import_bucket_file_box" v-if="show_import_bucket_file_box">
        <div class="suspension">
          <div class="import_bucket_file_content">
            <div class="import_bucket_file_head">
              <span>请选择要导入的Zip文件</span>
              <img
                :src="require('@/assets/images/NftCasting/block_x.png')"
                @click="show_import_bucket_file_box = false"
              />
            </div>
            <input type="file" @change="UploadNonHomogeneityAsync($event)" />
          </div>
        </div>
      </div>
      <div class="bucket_file_box" v-show="show_bucket_file_box">
        <div class="bucket_file_title">
          <span>桶文件</span>
          <img
            :src="require('@/assets/images/NftCasting/block_x.png')"
            @click="show_bucket_file_box = false"
          />
        </div>

        <div class="bucket_file_content">
          <div
            class="bucket_file_item"
            :key="index"
            v-for="(v, index) in bucket_file_list"
          >
            <img class="file_img" :src="v.file_url" />
            <img
              @click="RemoveBucketFileAsync(v)"
              class="remove_file"
              :src="require('@/assets/images/NftCasting/x.png')"
            />
          </div>
        </div>
        <div class="bucket_file_button">
          <el-button type="primary" @click="ShowImportBucketFileBox">
            导入Zip到桶
          </el-button>
        </div>
      </div>

      <div class="bucket_title">
        <span>文件桶管理</span>
        <img
          :src="require('@/assets/images/NftCasting/block_x.png')"
          @click="CloseMe"
        />
      </div>
      <div class="bucket_content">
        <div class="bucket_item" :key="index" v-for="(v, index) in bucket_list">
          <div class="bucket_left">
            <span>{{ v.bucket_name }}</span>
            <span>桶HASH:{{ v.file_group_hash }}</span>
            <span>创建时间:{{ v.create_time }}</span>
          </div>
          <div class="bucket_right">
            <el-button type="primary" @click="ShowBucketFileBox(v)">
              桶文件
            </el-button>
            <el-button type="primary" @click="RemoveBucketAsync(v)">
              删除桶
            </el-button>
          </div>
        </div>
      </div>
      <div class="bucket_btn_box">
        <el-button type="primary" @click="CreateBucketAsync">创建桶</el-button>
      </div>
    </div>
  </div>
</template>
  <script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
import { InputBoxTypeEnum } from "@/ts/SuperEnum";
import { ElMessageBox } from "element-plus";
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      show_import_bucket_file_box: false, //显示桶导入窗口
      show_bucket_file_box: false,
      select_bucket_vo: {},
      bucket_list: [],
      bucket_file_list: [],
    };
  },
  methods: {
    ShowBucketFileBox(v) {
      this.select_bucket_vo = v;
      this.GetBucketFilesListAsync();
      this.show_bucket_file_box = true;
    },
    CloseMe() {
      this.$parent.show_bucket_box = false;
    },
    ShowImportBucketFileBox() {
      this.show_import_bucket_file_box = true;
    },
    async RemoveBucketAsync(v) {
      Global.MessageBox("系统提示!", "您确定要删除此桶么?", async () => {
        let res = await Tools.GetHtmlAsync("RemoveBucket", { id: v.id });
        if (Tools.CheckHtmlIsOK(res)) {
          this.GetBucketListAsync();
          Tools.AlertSuccess("删除成功!");
        }
      });
    },
    async CreateBucketAsync() {
      let bucket_name = await Global.ShowInputBoxAsync(
        "请输入桶名称",
        "",
        InputBoxTypeEnum.TEXT,
        50
      );
      if (Tools.IsNotNull(bucket_name)) {
        let res = await Tools.GetHtmlAsync("CreateBucket", {
          bucket_name: bucket_name,
        });
        if (Tools.CheckHtmlIsOK(res)) {
          this.GetBucketListAsync();
        }
      }
    },
    /**
     * 导入到桶
     */
    async UploadNonHomogeneityAsync(event) {
      let files = event.target.files;
      if (files.length > 0) {
        await Global.ShowGlobalLoadingAsync("正在导入!", async (m) => {
          let res = await Tools.UploadFileAsync(
            "ImportToBucket",
            {
              file_group_hash: this.select_bucket_vo.file_group_hash,
            },
            files
          );
          if (Tools.CheckHtmlIsOK(res)) {
            let d = res.data;
            let bucket_name = d.bucket_name;
            let file_group_hash = d.file_group_hash;
            let obs_file_count = d.obs_file_count;
            Tools.AlertSuccess(`成功导入:${obs_file_count}个文件!`);
            this.show_import_bucket_file_box = false;
            this.GetBucketFilesListAsync();
          }
        });
      }
    },
    /**
     * 获取桶数据列表
     */
    async GetBucketListAsync() {
      let res = await Tools.GetHtmlAsync("GetBucketList", {});
      if (Tools.CheckHtmlIsOK(res)) {
        this.bucket_list = res.data.data;
      }
    },
    async GetBucketFilesListAsync() {
      let res = await Tools.GetHtmlAsync("GetBucketFilesList", {
        file_group_hash: this.select_bucket_vo.file_group_hash,
      });
      if (Tools.CheckHtmlIsOK(res)) {
        this.bucket_file_list = res.data.data;
      }
    },
    async RemoveBucketFileAsync(v) {
      Global.MessageBox("系统提示!", "您确定要删除桶文件么?", async () => {
        let res = await Tools.GetHtmlAsync("RemoveBucketFile", { id: v.id });
        if (Tools.CheckHtmlIsOK(res)) {
          Tools.AlertSuccess("删除成功!");
          this.bucket_file_list.ToRemove(v);
        }
      });
    },
  },
  mounted() {
    this.GetBucketListAsync();
  },
};
</script>
  
  <style scoped lang="scss">
.suspension {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
  height: 100%;
  background-color: var(--el-overlay-color-lighter);
  overflow: auto;
}

.bucket_body {
  position: fixed;
  top: 20%;
  left: 40%;
  width: 30%;
  border-radius: 2px;
  background-color: white;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 40%;
  .bucket_title {
    display: flex;
    justify-content: center;
    font-size: 16px;
    height: 30px;
    align-items: center;
    margin: 10px 0px 0 10px;
    img {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 10px;
    }
  }

  .bucket_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-basis: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .bucket_item {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      margin-bottom: 5px;
      font-size: 14px;
      .bucket_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 70%;
        overflow: hidden;
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          width: 100%;
          text-align: left;
        }
      }

      .bucket_right {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        button {
          height: 30px;
          padding: 2px 20px;
          border-radius: 10px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .bucket_btn_box {
    margin-top: auto;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    button {
      width: 90%;
      height: 36px;
      border-radius: 10px;
    }
  }
}

.bucket_file_box {
  position: fixed;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 50%;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .bucket_file_title {
    display: flex;
    justify-content: center;
    height: 30px;
    background-color: white;
    align-items: center;
    font-size: 16px;
    img {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 10px;
    }
  }

  .bucket_file_content {
    height: 100%;
    width: 100%;
    display: inline-block;
    padding: 5px 0px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .bucket_file_item {
      display: inline-block;
      float: left;
      width: 30%;
      margin-left: 2.5%;
      margin-bottom: 10px;
      border-radius: 10px;
      background-color: antiquewhite;
      overflow: hidden;
      position: relative;
      .file_img {
        width: 100%;
        height: 100%;
      }
      .remove_file {
        height: 15px;
        width: 15px;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }

  .bucket_file_button {
    height: 40px;
    text-align: center;
    button {
      height: 30px;
      width: 80%;
      border-radius: 10px;
    }
  }
}

.import_bucket_file_box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 310;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .import_bucket_file_content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px 20px 20px;
    border-radius: 10px;
    position: absolute;
    top: 40%;
    left: 40%;

    .import_bucket_file_head {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      height: 30px;
      img {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 10px;
      }
    }
  }
}
.el-button + .el-button {
  margin-left: 0px;
}
::v-deep.el-overlay {
  z-index: 3008 !important;
}
</style>