import router from '@/router';
import Config from './Config';
import Global from './Global';
import { AudioResourceEnum } from './SuperEnum';
import BackDataVO from './vo/BackDataVO';
import { ElMessage, ElMessageBox } from "element-plus";
var QrCode=require('qrcode-decoder');
let QRBase64Code = require("qrcode");
export default class Tools {

    /**
     * 判断是否是移动端
     * @returns 
     */
    static WebViewIsMobile() {
        if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
            return true; //移动端
        } else {
            return false; // PC端
        }
    }

    /**
     * 弹出消息框
     * @param title 
     * @param content 
     * @param success_fun 
     * @param cancel_fun
     */
    public static MessageBox(title: string, content: string, success_fun: () => void, cancel_fun: (() => void) | null = null) {
        Global.MessageBox(title, content, success_fun, cancel_fun);
    }

    /**
     * 设置对象
     * @param to_obj 
     * @param from_obj 
     */
    static SetObj(to_obj: any, from_obj: any) {
        if (to_obj == null)
            to_obj = {};
        if (to_obj && from_obj) {
            for (const key in from_obj) {
                if (Object.prototype.hasOwnProperty.call(from_obj, key)) {
                    const v = from_obj[key];
                    to_obj[key] = v;
                }
            }
        }
    }

    static IsJson(arg0: string): boolean {
        try {
            if (JSON.parse(arg0))
                return true;
        }
        catch (e) {

        }
        return false;
    }

    /**
     * 是否为JSON数组
     * @param arg0 
     * @returns 
     */
    static IsJsonArr(arg0: string) {
        let item = this.ToJsonVO(arg0);
        if (!item.flag)
            return false;
        if (((item.data?.hasOwnProperty("length") ?? null)))
            return true;
        return false;
    }

    static ToJsonVO(str: string): {
        flag: boolean,
        data: any
    } {
        try {
            return {
                flag: true,
                data: JSON.parse(str)
            };
        }
        catch (ex) {
            return {
                flag: false,
                data: null
            }
        }
    }
    /**
     * 倒序排列
     * @param arr 
     * @param select_pro 
     */
    public static OrderDesc<T>(arr: Array<T>, select_pro: (x: T) => number) {
        arr.sort((a, b) => {
            return select_pro(b) - select_pro(a);
        });
    }

    /**
     * 生成GUID
     */
    public static get GUID(): string {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }
    /**
     * 格式化时间到日期
     * @param _time 
     * @returns 
     */
    public static FormatDateTimeToDate(_time: number | string): string {
        if (_time == "" || _time == null)
            return _time;
        try {
            if (typeof _time == "string") {
                _time = _time.replace(/-/g, "/");
                if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

                }
                else {
                    _time = parseInt(_time);
                }
            }
            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(_time);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            let show_time = `${y}-${this.add0(m)}-${this.add0(d)}`;
            return show_time;
        }
        catch (e) {
            return typeof _time == "number" ? _time.toString() : _time;
        }
    }

    /**
     * 获取当前时间戳
     */
    public static get GetNowTime(): number {
        return Date.now();
    }

  /**
     * 生成二维码
     * @param url 
     */
   static async CreateQrCode(elem: any, url: string, w: number = 74, h: number = 74) {
    let qrcode = new QrCode.default();
    return qrcode(elem, {
        text: url, // 需要转换为二维码的内容
        width: w,
        height: h,
        colorDark: "#000000",
        colorLight: "#ffffff"
    });
}
  /**
     * 创建BASE64二维码
     */
   static async CreateBase64QRAsync(url: string) {
    try {
        return await QRBase64Code.toDataURL(url);
    }
    catch (e) {
        return "";
    }
}
    /**
     * 时间转换成时间戳
     * @param v 
     * @returns 
     */
    public static TimeToLong(v: string | number): number {
        if (typeof v == "number")
            return v;
        if (Tools.IsNull(v))
            return 0;
        v = v.replace(/-/g, "/")
        let time = new Date(v);
        return time.getTime();
    }



    public static GetBlob(canvas: HTMLCanvasElement) {
        //获取blob对象
        let base64 = canvas.toDataURL("image/jpeg", 1);
        let data = base64.split(",")[1];
        data = window.atob(data);
        let ia = new Uint8Array(data.length);
        for (var i = 0; i < data.length; i++) {
            ia[i] = data.charCodeAt(i);
        }
        return {
            blob: new Blob([ia], {
                type: "image/jpeg",
            }),
            base64: base64,
        };
    }
    /**
     * 随机数
     * @returns 
     */

    public static RandomNum(min: number, max: number): number {
        let v = Math.round(Math.random() * max + min);
        return v;
    }

    /**
     * 
     */
    // public static DJSTimeToString(inputTime: number) {
    //     var nowTime = Tools.GetNowTime;
    //     var times = Math.abs(inputTime - nowTime) / 1000; // times是剩余时间总的秒数 
    //     var h: any = parseInt((times / 60 / 60 % 24).toString()) ?? 0; //时
    //     // h = h < 10 ? '0' + h : h; //不足补0

    //     var m: any = parseInt((times / 60 % 60).toString()); // 分
    //     // m = m < 10 ? '0' + m : m;
    //     var s: any = parseInt((times % 60).toString()); // 当前的秒
    //     // s = s < 10 ? '0' + s : s;
    //     return `${h}:${m}:${s}`;
    // }

    /**
     * 设置数组
     * @param to_array 
     * @param from_arr 
     */
    static SetArray(to_array: any[], from_arr: any[]) {
        to_array.Clear();
        to_array.PushAll(from_arr);
    }

    /**
     * 清空对象
     */
    static ClearObj(obj: any) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                delete obj[key];
            }
        }
    }


    /**
     * 时间差转换成可读时间
     * @param difftime 时间差
     * @returns 
     */
    public static DifftimeToString(difftime: number, formake: "all" | "minutes" | "minutes_2" = "all", format: "1" | "2" = "1"): string {
        try {

            difftime = difftime / 1000;
            let days = parseInt((difftime / 86400).toString());
            if (difftime < 86400)
                days = 0;
            let hours = parseInt((difftime / 3600).toString()) - 24 * days;

            if (difftime < 3600)
                hours = 0;
            let minutes = parseInt((difftime % 3600 / 60).toString());
            if (minutes <= 0)
                minutes = 0;
            let seconds = parseInt((difftime % 60).toString());
            if (seconds <= 0)
                seconds = 0;
            if (formake == "all") {
                if (days > 0) {
                    if (format == "1") {
                        return `${days}天${hours}小时${minutes}分${seconds}秒`;
                    }
                    else if (format == "2") {
                        return `${days}天 ${this.add0(hours)}:${this.add0(minutes)}:${this.add0(seconds)}`;
                    }

                }
                else if (hours > 0) {
                    if (format == "1") {
                        return `${hours}时${minutes}分${seconds}秒`;
                    }
                    else {
                        return `${this.add0(hours)}:${this.add0(minutes)}:${this.add0(seconds)}`;
                    }
                }
                else {
                    if (format == "1") {
                        return `${this.add0(minutes)}分${this.add0(seconds)}秒`;
                    }
                    else {
                        return `${this.add0(minutes)}:${this.add0(seconds)}`;
                    }
                }
            }
            else if (formake == "minutes_2") {
                if (minutes > 0) {
                    return `${minutes}分${this.add0(seconds)}`;
                }
                else {
                    return `${this.add0(seconds)}`;
                }
            }
            else {
                return `${this.add0(minutes)}:${this.add0(seconds)}`;
            }

        }
        catch (ex) {

        }
        return "";
    }

    private static add0(m: number | string) {
        return (typeof m == "string" ? parseInt(m) : m) < 10 ? '0' + m : m
    }

    public static FormatAddress(address: string) {
        if (!address)
            return "";
        let ret_address = address;
        if (address.length > 10) {
            ret_address = address.substring(0, 6) + "..." + address.substring(address.length - 6);
        }
        return ret_address;
    }


    /**
         * 格式化时间成年月
         * @param time 
         * @returns 
         */
    public static FormatYMDate(_time: string | number): string {
        let time: string = ""
        if (typeof _time == "number") {
            time = Tools.ShowDateTime(_time);
        }
        else {
            time = _time;
        }
        let appointDate = time
            .replace("", ":")
            .replace(/\:/g, "-")
            .split("-");
        let dateTime = appointDate[1] + "年" + appointDate[2] + "月";
        return dateTime;
    }
    /**
     * Home页面时间显示方式
     * @param _time 
     * @returns 
     */
    public static ShowHomeDateTime(_time: number | string): string {
        if (typeof _time == "string") {
            _time = _time.replace(/-/g, "/");
            if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

            }
            else {
                _time = parseInt(_time);
            }
        }

        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(_time);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        let show_time = `${this.add0(h)}:${this.add0(mm)}:${this.add0(s)}`;
        //检查是否是今天
        let now_time = new Date(this.GetNowTime);
        if (now_time.getFullYear() == y && now_time.getMonth() + 1 == m && now_time.getDate() == d) {
            show_time = `${this.add0(h)}:${this.add0(mm)}`;
        }
        else {
            show_time = `${m}月${d}日`;
        }
        // else {
        //     show_time = `${y}-${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(mm)}`;
        // }
        return show_time;
    }

    /**
     * 显示时间
     * @param time 时间
     * @returns 
     */
    public static ShowDateTime(_time: number | string): string {

        if (typeof _time == "string") {
            _time = _time.replace(/-/g, "/");
            if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

            }
            else {
                _time = parseInt(_time);
            }
        }

        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(_time);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        let show_time = `${this.add0(h)}:${this.add0(mm)}:${this.add0(s)}`;
        //检查是否是今天
        let now_time = new Date(this.GetNowTime);
        if (now_time.getFullYear() == y && now_time.getMonth() + 1 == m && now_time.getDate() == d) {
            show_time = `${this.add0(h)}:${this.add0(mm)}`;
        }
        else {
            show_time = `${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(mm)}`;
        }
        // else {
        //     show_time = `${y}-${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(mm)}`;
        // }
        return show_time;
    }

    /**
     * 检测是否为空
     * @param v 
     * @returns 
     */
    public static IsNull(v?: string): boolean {
        if (v == null || v == "null" || v == undefined || v == "undefined" || v == "")
            return true;
        return false;
    }
    /**
     * 是否不是空
     * @param v 
     * @returns 
     */
    public static IsNotNull(v?: string): boolean {
        return !this.IsNull(v);
    }
    /**
     * 设置本地存储
     * @param key 
     * @param v 
     */
    public static SetLocalStorage(key: string, v: string): void {
        localStorage.setItem(key, v);
    }

    /**
     * 获取本地存储
     * @param key 
     * @returns 
     */
    public static GetLocalStorage(key: string, _default: string): string {
        let v = localStorage.getItem(key);
        if (Tools.IsNull(key))
            return "";
        return v as string;
    }

    /**
     * 弹出成功消息
     * @param SUCCESS_MESSAGE 
     */
    static AlertSuccess(SUCCESS_MESSAGE: string): void {
        ElMessage({
            message: SUCCESS_MESSAGE,
            type: "success"
        });
    }

    /**
     * 弹出失败消息
     * @param ERROR_MESSAGE 
     */
    static AlertError(ERROR_MESSAGE: string | undefined) {
        ElMessage.error(ERROR_MESSAGE);
    }

    /**
     * 常规提示
     */
    static AlertInfo(Info_Message: string | undefined) {
        
        ElMessage({
            message: Info_Message,
            type: "info"
        })
    }

    /**
     * 警告提示
     */
    static AlertWarning(warning_message: string | undefined) {
        ElMessage({
            message: warning_message,
            type: "warning"
        })
    }

    /**
     * 跳转
     * @param href 字符串跳转 或 数字跳转 -1代表返回
     * @param query Get传参
     * @param params Post传参
     * @param to_replice 是否替换方式跳转
     */
    public static GoTo(href: string | number, query: any = {}, params: any = {}, to_replice: boolean = false): void {
        if (typeof href == "string") {
            let name: string = "";
            for (let i = 0; i < router.getRoutes().length; i++) {
                const v = router.getRoutes()[i];
                if (v.path == href) {
                    name = v.name?.toString() as string;
                }
            }

            Global.params_data_dic[href] = params;
            if (to_replice) {
                Global.app.$router.replace({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
            else {
                Global.app.$router.push({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
        }
        else if (typeof href == "number") {
            switch (href) {
                case -1:
                    Global.app.$router.back();
                    break;
                default:
                    Global.app.$router.go(href);
                    break;
            }
        }
    }

    /**
     * 获取当前页面URL路径 
     * @returns 
     */
    public static NowPageURL() {
        let v = router;
        let path = v.currentRoute.value.path;
        return path;
    }

    /**
     * 获取当前的Post传参
     * @returns 
     */
    public static GetParams(href?: any): any {
        if (Tools.IsNull(href)) {
            let v = router;
            let path = v.currentRoute.value.path;

            return Global.params_data_dic[path] ?? {};
        }
        else {
            //path
            if (href) {
                if (typeof href == "string") {
                    return Global.params_data_dic[href] ?? {};
                }
                else if (typeof href == "object") {
                    if (Tools.IsNotNull(href.path)) {
                        return Global.params_data_dic[href.path] ?? {};
                    }
                }
            }

            return {};
        }
    }
    /**
     * 清理Param
     */
    public static ClearParam() {

        let v = router;
        let path = v.currentRoute.value.path;
        delete Global.params_data_dic[path];
    }

    /**
     * 当前路由页面
     * @returns 
     */
    public static GetNowRouteHref() {
        return Global.app && Global.app.$route.path;
    }

    /**
     * 获取当前的Get传参
     * @returns 
     */
    public static GetQuery(): any {
        return Global.app.$route.query;
    }

    /**
     * 显示LOG
     * @param tag 标签
     * @param msg 日志内容
     * @param color 颜色
     * @param font_size 字体大小
    */
    static ShowLog(tag: string, msg: any, color: string = "#0a0", font_size: number = 12): void {
        console.log(`%c【${tag}】:${msg}`, `color:${color};font-size:${font_size}px`);
    }


    /**
     * 检测图片是否过大
     * @param file 
     * @returns 
     */
    private static CheckImageSoBig(file: File | Blob): boolean {
        switch (file.type.toLowerCase()) {
            case "application/x-zip-compressed":
                return false;
        }
        if (file.size / 1024 / 1024 > 20)
            return true;
        return false;
    }

    /**
     * 拷贝内容到剪切板
     * @param content 
     */
    public static CopyContentToClipboardAsync(clip_content: string): Promise<boolean> {
        return new Promise<boolean>(r => {
            if (Global.app) {
                Global.app.$copyText(clip_content).then(
                    function (e: any) {
                        r(true);
                    },
                    function (e: any) {
                        r(false)
                    }
                );
            }
            else {
                r(false);
            }
        });

    }

    /**
     * 异步上传文件
     * @param action 
     * @param data 
     * @param files 
     * @param success 
     */
    static UploadFileAsync(action: string, data: any, files: Blob[]): Promise<{
        state: number,
        msgstate?: string,
        key?: string,
        error_msg: string,
        data?: {
            OriginalFileNameNoExtension: string,
            OriginalFileName: string,
            FileName: string,
            FileURL: string,
            FileSize: number,
            MimeType: string,
            FileHash: string
        }[]
    }> {
        return new Promise(r => {
            try {
                this.UploadFile(action, data, files, (res => {
                    r(res);
                }), (error_msg) => {
                    r({
                        state: 0,
                        error_msg: error_msg
                    })
                });
            }
            catch (ex) {
                r({
                    state: 0,
                    error_msg: "上传失败!"
                })
            }
        });
    }

    /**
     * 上传文件
     * @param action 
     * @param data 
     * @param files 
     * @param success 
     */
    static UploadFile(action: string, data: any, files: Blob[], success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: {
            OriginalFileNameNoExtension: string,
            OriginalFileName: string,
            FileName: string,
            FileURL: string,
            FileSize: number,
            MimeType: string,
            FileHash: string
        }[]
    }) => void, error_fun: (err_msg: string) => void) {
        try {
            if (data == null)
                data = {};
            Tools.ShowLog(action, "上传文件", "	#1C86EE");
            data.login_token = Global.GetLoginToken;
            data.use_platform_guid = Global.GetUsePlatformGuid;
            let url = `${Config.API_URL}?a=${action}` //API_URL + "?a=" + action;
            var xhr = new XMLHttpRequest();
            let formData = new FormData();

            for (let key in data) {
                url += `&${key}=${encodeURIComponent(data[key])}`;
            }

            //进行压缩


            // if (files instanceof Blob) {
            //     formData.append('fileUpload', files, "new_file");
            // }
            // else {
            //     for (let i = 0; i < files.length; i++) {
            //         const f = files[i];
            //         formData.append('fileUpload', f);
            //     }
            // }

            for (let i = 0; i < files.length; i++) {
                const v = files[i];
                formData.append('fileUpload', v, `new_file_${i}`);
            }


            xhr.open("POST", url, true);
            // xhr.withCredentials = true;

            // xhr.setRequestHeader("Content-type", `multipart/form-data;`);
            // xhr.upload.onprogress = (ev) => {
            //     console.log(`进度:${Math.ceil(ev.loaded * 100 / ev.total)}%`);
            // };
            xhr.onload = function (e) {
                if (this.status == 200 || this.status == 304) {
                    if (success != null)
                        success(JSON.parse(this.responseText));
                }
                else {
                    Tools.AlertError("网络请求失败 错误:" + this.status);
                }
            };
            xhr.send(formData);
        }
        catch (e) {
            Tools.AlertError("上传失败!");
            error_fun && error_fun("上传失败!");
        }
    }


    /**
     * 补0
     * @param num 
     * @param n 
     * @returns 
     */
    public static Pad(num: number, n: number): string {
        console.log(`要处理的数据:${num}`);
        let len = num.toString().length;
        let ret_num = num.toString();
        while (len < n) {
            ret_num = "0" + num;
            len++;
        }
        return ret_num;
    }

    /**
     * 获取远程URL数据
     * @param url 
     */
    static GetURLAsync(url: string, data: any, method: "POST" | "GET" = "POST") {
        return new Promise<string>((r, errfun) => {
            try {
                var xhr = new XMLHttpRequest();
                var formdata: string = "";
                for (let key in data) {
                    formdata += key + "=" + encodeURIComponent(data[key]) + "&";
                }
                if (formdata != "") {
                    formdata = formdata.substr(0, formdata.length - 1);
                }
                xhr.open("GET", url, true);
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

                xhr.onload = function (e) {
                    if (this.status == 200 || this.status == 304) {
                        r(this.responseText);
                    }
                    else {

                        errfun("网络请求失败 错误:" + this.status);
                    }
                };
                xhr.ontimeout = function (e) {
                    console.log(`请求超时........`);
                };
                xhr.onerror = function (e) {

                    errfun("请求失败!");
                };
                xhr.send(formdata);
            }
            catch (e) {

                errfun("出错了!");
            }
        });
    }

    /**
     * 打开命令链接
     * @param action 
     * @param data 
     */
    static OpenActionURL(action: string, data: any) {

        if (data == null)
            data = {};
        data.login_token = Global.GetLoginToken;
        data.use_platform_guid = Global.GetUsePlatformGuid;
        let url = `${Config.API_URL}?a=${action}`;
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const v = data[key];
                url += `&${key}=${v}`;
            }
        }
        window.location.href = url;
    }

    /**
     * 获取HTML
     * @param action 
     * @param data 
     * @param success 
     * @param fail 
     */
    static GetHtml(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void, files: File[] = []) {
        try {
            if (data == null)
                data = {};


            Tools.ShowLog(action, "HTML请求", "	#1C86EE");
            data.login_token = Global.GetLoginToken;
            data.use_platform_guid = Global.GetUsePlatformGuid;
            let url = `${Config.API_URL}?a=${action}`;

            //判断是否是LookBaoApp

            var xhr = new XMLHttpRequest();
            var formdata: FormData | string;
            if (files.length > 0) {
                formdata = new FormData();
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined)
                        url += "&" + key + "=" + encodeURIComponent(data[key]);
                }
                // if (url != "") {
                //     url = url.substring(0, url.length - 1);
                // }
                if (files) {
                    if (files.length > 0) {
                        for (let i = 0; i < files.length; i++) {
                            const f = files[i];
                            formdata.append('fileUpload', f);
                        }
                    }
                }
            }
            else {
                formdata = "";
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined)
                        formdata += key + "=" + encodeURIComponent(data[key]) + "&";
                }
                if (formdata != "") {
                    formdata = formdata.substr(0, formdata.length - 1);
                }
            }
            xhr.open("POST", url, true);
            if (files.length > 0) {
                // xhr.setRequestHeader("Content-type", "multipart/form-data");
            }
            else {
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            }


            xhr.onload = function (e) {
                try {
                    if (this.status == 200 || this.status == 304) {
                        if (success != null)
                            success(JSON.parse(this.responseText));
                    }
                    else {
                        Tools.AlertError("网络请求失败 错误:" + this.status);
                    }
                }
                catch (ex) {
                    console.error(ex);
                }
            };
            xhr.send(formdata);

        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHtmlAsync(action: string, data: any, files: File[] = []): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {

        return new Promise((successfun, errorfun) => {
            this.GetHtml(action, data, (res) => {
                Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                Tools.ShowLog(`HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            }, files);
        });
    }



    /**
     * 获取DAPP HTML
     * @param action 
     * @param data 
     * @param success 
     * @param fail 
     */
    static GetHtmlDApp(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void, files: File[] = []) {
        try {
            if (data == null)
                data = {};


            Tools.ShowLog(action, "HTML请求", "	#1C86EE");
            data.login_token = Global.GetLoginToken;
            data.use_platform_guid = Global.GetUsePlatformGuid;
            let url = `${Config.DAPP_URL}?a=${action}`;

            //判断是否是LookBaoApp

            var xhr = new XMLHttpRequest();
            var formdata: FormData | string;
            if (files.length > 0) {
                formdata = new FormData();
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined)
                        url += "&" + key + "=" + encodeURIComponent(data[key]);
                }
                // if (url != "") {
                //     url = url.substring(0, url.length - 1);
                // }
                if (files) {
                    if (files.length > 0) {
                        for (let i = 0; i < files.length; i++) {
                            const f = files[i];
                            formdata.append('fileUpload', f);
                        }
                    }
                }
            }
            else {
                formdata = "";
                for (let key in data) {
                    if (data[key] != null && data[key] != undefined)
                        formdata += key + "=" + encodeURIComponent(data[key]) + "&";
                }
                if (formdata != "") {
                    formdata = formdata.substr(0, formdata.length - 1);
                }
            }
            xhr.open("POST", url, true);
            if (files.length > 0) {
                // xhr.setRequestHeader("Content-type", "multipart/form-data");
            }
            else {
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            }


            xhr.onload = function (e) {
                try {
                    if (this.status == 200 || this.status == 304) {
                        if (success != null)
                            success(JSON.parse(this.responseText));
                    }
                    else {
                        Tools.AlertError("网络请求失败 错误:" + this.status);
                    }
                }
                catch (ex) {
                    console.error(ex);
                }
            };
            xhr.send(formdata);

        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHtmlDAppAsync(action: string, data: any, files: File[] = []): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {

        return new Promise((successfun, errorfun) => {
            this.GetHtmlDApp(action, data, (res) => {
                Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                Tools.ShowLog(`HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            }, files);
        });
    }



    /**
     * 获取资源地址
     * @param url 
     */
    static GetResourceURL(url: string, pic_mode: "none" | "800" | "300" | "160" | "f" | "f160" = "none") {
        return Global.GetCoinIconURL(url, pic_mode);
    }

    /**
     * 获取文件系统路径
     */
    static GetFileSystemPath(): string {
        switch (window.device.platform) {
            case "IOS":
                return cordova?.file?.tempDirectory;
            case "Android":
                return cordova.file.applicationStorageDirectory;
            default:
                return "";
        }
    }

    /**
     * 获取文件系统路径结构体
     * @returns 
     */
    static GetFileSystemPathVO(): CordovaFilePath {
        return cordova.file;
    }

    /**
     * 检测如果是空
     * @param msg 
     */
    static StrIsNull(msg?: string | null): boolean {
        if (msg == "" || msg == null || msg == "undefined" || msg == "null") {
            return true;
        }
        return false;
    }

    /**
     * 检测字符串不是空
     * @param msg 
     */
    static StrIsNotNull(msg?: string | null): boolean {
        return !this.StrIsNull(msg);
    }


    static RemoveLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }


    /**
     * 设置SESSION
     * @param key F
     * @param value 
     */
    static SetSessionStorage(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    /**
     * 获取SESSION值
     * @param key 
     */
    static GetSessionStorage(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    static RemoveSessionStorage(key: string) {
        sessionStorage.removeItem(key);
    }

    /**
     * 清空全部session
     */
    static RemoveAllSession() {
        sessionStorage.clear();
    }
    /**
     * 检测数据包是否正确
     * @param v 
     * @returns 
     */
    public static CheckDataIsOK(v: {
        flag: boolean,
        _this: any,
        err_msg: string,
        data_vo: BackDataVO | null
    } | null, show_error_msg: boolean = true): boolean {
        if (v != null) {
            if (v.flag) {
                if (v.data_vo != null) {
                    switch (v.data_vo.STATUS) {
                        case -99: //未登录
                            Tools.RemoveLocalStorage("select_uid");
                            Tools.RemoveLocalStorage("login_user_vo");
                            Tools.RemoveLocalStorage("login_token");
                            Tools.GoTo("/login");
                            return false;
                        case 0: //请求失败
                            if (show_error_msg)
                                Tools.AlertError(v.data_vo.ERROR_MESSAGE);
                            break;
                        case 1: //请求正常返回
                            return true;
                    }
                }

            }
        }
        return false;
    }
    /**
     * 检测数据是否正确
     * @param res 
     * @param show 是否显示 默认显示错误提示
     */
    static CheckHtmlIsOK(res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }, show_error_msg: boolean = true) {
        switch (res.state) {
            case -99:
                Tools.GoTo("/login");
                if (show_error_msg) {
                    Tools.AlertError("您还没有登录!");
                }
                return false;
            case 1:
                return true;
            default:
                if (show_error_msg) {
                    Tools.AlertError(res.error_msg);
                }
                else {
                    console.log(`错误:${res.error_msg}`);
                }
                return false;
        }
    }

    /**
     * 时间戳转换成时分秒
     * @param timestamp 
     */
    static UnixTimestampToDateTime(timestamp: number): string {
        if (typeof timestamp == "number") {
            if (timestamp.toString().length == 10)
                timestamp *= 1000;
        }
        if (typeof timestamp == "object")
            return "";
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + this.add0(D) + this.add0(h) + this.add0(m) + this.add0(s.toString());
    }

    /**
     * 播放声音
     * @param url 
     */
    public static PlayVoice(url: string | AudioResourceEnum): void {
        if (Global.Mute) //如果是静音的则啥声音都没有
            return;
        let v = Global.play_voice_run_dic[url];
        if (v) {
            if (v.time <= Tools.GetNowTime) {
                delete Global.play_voice_run_dic[url];
            }
            else {
                return;
            }
        }
        Global.play_voice_run_dic[url] = {
            time: Tools.GetNowTime + (3 * 1000)
        };
        let audio = document.createElement("audio");
        try {
            audio.src = url;
            audio.play();
            audio.onended = () => {
                this.ClearPlayVoice(url);
            };
        }
        catch (e) {
        }

    }

    /**
     * 播放音频状态
     * @param url 
     */
    private static ClearPlayVoice(url: string): void {
        let v = Global.play_voice_run_dic[url];
        if (v) {
            if (v.time >= 3 * 1000) {
                delete Global.play_voice_run_dic[url];
            }
        }
    }



    /**
     * 获取DAPP HTML
     * @param action 
     * @param data 
     * @param success 
     * @param fail 
     */
    static GetHTMLDApp(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void) {
        try {
            if (data == null)
                data = {};

            Tools.ShowLog(action, "DAPP HTML请求", "	#1C86EE");
            data.login_token = Global.GetLoginToken;
            let url = `${Config.DAPP_URL}?a=${action}`;
            var xhr = new XMLHttpRequest();
            var formdata: string = "";
            for (let key in data) {
                formdata += key + "=" + encodeURIComponent(data[key]) + "&";
            }
            if (formdata != "") {
                formdata = formdata.substr(0, formdata.length - 1);
            }
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

            xhr.onload = function (e) {
                if (this.status == 200 || this.status == 304) {
                    if (success != null)
                        success(JSON.parse(this.responseText));
                }
                else {
                    Tools.AlertError("网络请求失败 错误:" + this.status);
                }
            };
            xhr.send(formdata);

        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHTMLDAppAsync(action: string, data: any): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {
        return new Promise((successfun, errorfun) => {
            this.GetHTMLDApp(action, data, (res) => {
                Tools.ShowLog(`DAPP HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                Tools.ShowLog(`DAPP HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            });
        });
    }

    /**
     * 打开链接
     * @param title 标题
     * @param url 地址
     * @param popup 是否悬浮默认不悬浮
     */
    static OpenURLToWebView(title: string, url: string, popup: boolean = false) {
        if (!popup) {
            Tools.GoTo("/webview", {}, {
                title: title,
                url: url
            });
        }
        else {
            Global.app && Global.app.OpenURLToWebView(title, url);
        }
    }

    static ClosePopupWebView() {
        Global.app && Global.app.ClosePopupWebView();
    }
}