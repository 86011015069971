/**
 * 红包打包模式
 */
export enum RedPacketToolsModelEnum {
    /**
     * 仅创建模板
     */
    OnlyCreateRedPackTemplate,
    /**
     * 根据模板ID打包红包
     */
    UseTemplateIDToBuildRedPack,
    /**
     * 创建模板并生成红包道具
     */
    CreateTemplateAndBuildRedPack
}
/**
 * 主题枚举
 */
export enum ThemeTypeEnum {
    black,
    white
}

/**
 * 验证码类型模式
 */
export enum SlideVerificationTypeEnum {
    /**
     * 手机号申请验证
     */
    VerificationPhone = 1
}

/**
 * 小程序类型
 */
export enum AppletTypeEnum {
    /**
     * 
     */
    APP = 0,
    /**
     * 
     */
    Game = 1,

}

/**
 * 登录资源类型
 */
export enum LoginSourceTypeEnum {
    NONE = 0,
    微信APP登录 = 1,
    微信公众号登录 = 2
}

/**
 * 道具类型
 */
export enum PropTypeEnum {
    盲盒道具 = 1,
    兑换实物道具 = 2,
    红包道具 = 3,
    合成道具 = 4,
    /**
     * 具有特殊功能的道具
     */
    特殊道具 = 5
}

/**
 * 消息类型
 */
export enum ChatTypeEnum {
    /**
     * 无
     */
    NONE,
    /**
     * 时间
     */
    TIME,
    /**
     * 文本
     */
    TEXT,
    /**
     * 图片
     */
    IMAGE,
    /**
     * 文件
     */
    FILE,
    /**
     * 音频
     */
    VOICE,
    /**
     * 视频
     */
    VIDEO,
    /**
     * 短语音(对讲)
     */
    SPEECH,
    /**
     * 位置
     */
    POSITION,
    /**
     * 转账
     */
    TRANSACTION,
    /**
     * 红包
     */
    REDPACKAGE,
    /**
     * 名片
     */
    BUSINESSCARD,
    /**
     * 撤销消息
     */
    WITHDRAW,
    /**
     * 转发消息
     */
    FORWARD,
    /**
     * 小程序分享
     */
    APPLET_SHARE,
    /**
     * 消息通知(显示一些信息)
     */
    INFORMATION_NOTICE,
    /**
     * 表情
     */
    FACE,
    /**
     * 新增好友
     */
    NEW_FRIEND,
    /**
     * 新加群
     */
    NEW_GROUP,
    /**
     * 创建新群
     */
    CREATE_GROUP,
    /**
     * 邀请入群
     */
    INVITATION_GROUP,
    /**
     * 上传消息
     */
    UPLOADING,
    /**
     * NFT资产转让
     */
    NFTTransfer,
    /**
     * 法币转账
     */
    LegalCurrencyTransfer,
    /**
     * MIU转账
     */
    MIUTransfer
};

/**
 * 上传文件类型
 */
export enum UploadFileTypeEnum {
    NONE = 0,
    FILE = 1,
    IMAGE = 2,
    Audio = 3,
    VIDEO = 4,
}

/**
 * 音频资源枚举
 */
export enum AudioResourceEnum {
    /**
     * 消息声音
     */
    CUE_TONE = "mp3/cue_tone.mp3",
    /**
     * 通知声音
     */
    NOTICE = "mp3/notice.mp3",
    /**
     * 扫码声音
     */
    SCAN_CODE = "mp3/scan_code.mp3",
}

/**
 * 音视频类型
 */
export enum CALL_MEDIA_TYPE {
    /**
     * 视频
     */
    CALL_MEDIA_TYPE_VIDEO = 1,
    /**
     * 音频
     */
    CALL_MEDIA_TYPE_AUDIO = 2
}


/**
 * 转账交易状态
 */
export enum TransactionStatusEnum {
    NONE = -1,
    成功 = 1,
    失败 = 0
}

/**
 * 输入框类型
 */
export enum InputBoxTypeEnum {
    /**
     * 多行文本
     */
    TextArea = 0,
    /**
     * 文本
     */
    TEXT = 1,
    /**
     * 上传文件
     */
    UploadFile = 2,
    /**
     * 密码框
     */
    Password = 3,
    /**
     * 日期+时间控件
     */
    DateTime = 4,
    /**
     * 日期控件
     */
    Date = 5,
    /**
     * 数字类型控件
     */
    Number = 6
}

/**
 * NFT资源结构体类型
 */
export enum NFTAssetStructureTypeEnum {
    AssetTitle, //资源标题
    AssetsThumbnails, //资源缩略图
    DATA, //数据类型
    RemoteFiles, //远程文件组(可上传一堆)
    ImageFiles, //图组
}

/**
 * 资源文件类型
 */
export enum NFTAssetFileTypeEnum {
    /**
     * 图片类型
     */
    IMAGE,

    /**
     * 文件类型
     */
    FILE
}

export enum DemolitionChangeTypeEnum {
    NONE = 0,
    服务费 = 1,
    版权费 = 2,
    收藏佣金 = 3,
    盈收 = 4,
    用户充值 = 5,
    资金冻结 = 6,
    扣除冻结资金 = 7,
    资金解冻 = 8,
    铸造 = 9,
    资产转移收益 = 10,
    用户出价 = 11,
    出价撤销 = 12,
    购买资产 = 13,
    同意出价 = 14,
    拒绝出价 = 15,
    资产赠送 = 16,
    扣除资金 = 17,
    空投 = 18,
    零钱转账 = 19,
    提现申请 = 20,
    提现成功 = 21,
    提现失败 = 22,
    拒绝提现资金回滚 = 23,
    使用道具 = 24,
    打开红包 = 25,
    购买盲盒 = 26,
    删除道具 = 27,
    道具转赠 = 28,
    盲盒空投 = 29,
    道具合成 = 30,
    藏品合成 = 31,
    MIU转账 = 32,
    MIU消耗 = 33,
    玲珑塔合成 = 34,
    好友购买赠送 = 35,
    实名MIU赠送 = 36,
    新用户注册赠送 = 37,
    MIU签到 = 38,
    熔炉零钱扣费 = 39,
    熔炉MIU扣费 = 40,
    熔炉投掷藏品 = 41,
    熔炉投掷道具 = 42,
    通关证明藏品销毁 = 43,
    通关证明道具销毁 = 44,
    道具置换绑定 = 45,
    藏品置换绑定 = 46,
    置换 = 47,
    创建自定义零钱红包模板 = 48,
    创建自定义MIU红包模板 = 49,
    打包零钱红包 = 50,
    打包MIU红包 = 51,
    系统级增加MIU = 52,
    系统级扣除MIU = 53,
    系统级增加零钱 = 54,
    系统级扣除零钱 = 55
}

/**
 * 通知类型
 */
export enum NoticeTypeEnum {
    群申请通知 = 1,
    群审核通过通知 = 2,
    群审核拒绝通知 = 3,
    好友申请通知 = 4,
    好友审核通过通知 = 5,
    邀请入群通知 = 6,
    好友审核拒绝通知 = 7,
    开卡成功通知 = 8,
    开卡失败通知 = 9,
    收到转账通知 = 10,
    转账失败通知 = 11,
    移除群聊通知 = 12,
    删除好友通知 = 13,
    NFT资产出价通知 = 14,
    NFT资产购买通知 = 15,
    NFT铸造申请 = 16,
    NFT同意铸造 = 17,
    NFT拒绝铸造 = 18,
    获得首页铸造权 = 19,
    取消首页铸造权 = 20,
    实名认证 = 21,
    资产同步通知 = 22, //资产同步通知
    提现成功通知 = 23,
    提现拒绝通知 = 24,
    购买盲盒通知 = 25,
}

/// <summary>
/// 统计总数枚举类型
/// </summary>
export enum StatisticalCountingTypeEnum {
    NONE = 0,
    交易记录 = 1,
    转赠记录 = 2
}


/// <summary>
/// 转账类型
/// </summary>
export enum TransferTypeEnum {
    转账 = 0,
    红包 = 1,
    购物 = 2,
    直播红包 = 3,
    直播福袋 = 4,
    直播礼物 = 5,
    直播红包发放 = 6,
    直播福袋发放 = 7,
    购买NFT藏品 = 8,
    零钱转账 = 9
}

/**
 * 扫码类型
 */
export enum QRTypeEnum {
    好友名片 = 1,
    群名片 = 2,
    开放平台登录 = 3
}

/**
 * 财产类型
 */
export enum DemolitionTypeEnum {
    None = 0, //未知
    零钱 = 1,
    系统币 = 2
}

/**
 * 直播状态
 */
export enum LiveStateEnum {
    直播就绪 = 0,
    正在直播 = 1,
    停止直播 = 2,
    主播离开 = 3,
    主播暂停 = 4
}

/**
 * 币种体系枚举
 */
export enum CurrencySystemIdEnum {
    LookBao = 1,
    BTC = 2,
    ETH = 3,
    USDT_ERC20 = 4,
    USDT_TRC20 = 5,
    USDT_OMNI = 6,
    BSC = 7,
    SOL = 8,
    HECO = 9,
    TRX = 10,
    DOGE = 11,
    EOS = 12,
}

/**
 * 交易订单状态
 */
export enum OrderStateEnum {
    待支付 = 0,
    正在支付 = 1,
    失败 = 2,
    支付完成 = 3,
    已退款 = 4
}

/**
 * 订单交易类型
 */
export enum OrderTransactionTypeEnum {
    充值 = 1
}

/**
 * 订单交易方式
 */
export enum OrderPaymentMethodEnum {
    微信 = 1,
    支付宝 = 2,
    第三方微信 = 3,
    第三方支付宝 = 4
}