
import Tools from "./Tools";
import BigNumber from "bignumber.js";

/**
 * 数字补位
 */
Number.prototype.ComplementNumber = function (n: number, char: string = "0") {
    return (Array(n).join(char) + this).slice(-n);
}

/**
 * 保留小数不进位
 */
Number.prototype.KeepDecimal = function (n: number = 2) {
    let v = new BigNumber(this.toString());
    let ls_v = v.toString();
    let find_dian = false;
    let ret_v = "";
    let run_count = 0;
    for (let i = 0; i < ls_v.length; i++) {
        const m = ls_v[i];
        if (find_dian) {
            run_count++;
            if (run_count > n)
                break;
        }
        ret_v += m;
        if (m == ".")
            find_dian = true;
    }
    return ret_v;
}

/**
 * 数字切割成字符串
 */
Array.prototype.ArrayToString = function (limit_str: string): string {
    return this.join(limit_str);
}

Array.prototype.JsonToVO = function <T>() {
    return <T>JSON.parse(this as any);
}

Array.prototype.ToJson = function () {
    return JSON.stringify(this);
}

/**
 * 添加一堆
 */
Array.prototype.PushAll = function <T>(arr: Array<any>) {
    let _this_arr: Array<T> = this;
    arr.forEach(v => {
        _this_arr.push(v);
    });
}

/**
 * 正序
 */
Array.prototype.OrderBy = function <T>(select_pro: (x: T) => number): void {
    let arr: Array<T> = this;
    arr.sort((a, b) => {
        return select_pro(a) - select_pro(b);
    });
}

/**
 * 倒序
 */
Array.prototype.OrderByDesc = function <T>(select_pro: (x: T) => number): void {
    let arr: Array<T> = this;
    arr.sort((a, b) => {
        return select_pro(b) - select_pro(a);
    });
}
/**
 * 根据索引获取数组内容
 */
Array.prototype.GetValueOfIndex = function <T>(index: number): {
    flag: boolean,
    data: T | null
} {
    let arr: Array<any> = this;
    if (arr.length >= index + 1) {
        return {
            flag: true,
            data: <T>arr[index]
        };
    }
    return {
        flag: false,
        data: null
    };
}

/**
 * 查询Linq
 */
Array.prototype.Where = function <T>(where_fun: (x: T) => boolean): T[] {
    let t_list: T[] = [];
    for (let i = 0; i < this.length; i++) {
        const v = this[i];
        if (where_fun(v)) {
            t_list.push(v);
        }
    }
    return t_list;
}

/**
 * 求和
 */
Array.prototype.Sum = function <T>(callfun: (x: T) => number): number {
    let arr = this as Array<T>;
    let all_count = 0;
    arr.forEach(v => {
        all_count += callfun(v);
    });
    return all_count;
}

/**
 * 检测是否存在
 */
Array.prototype.IsFind = function <T>(where_fun: (x: T) => boolean): boolean {
    let arr = this as Array<T>;
    return arr.Where(where_fun).length > 0;
}

Array.prototype.Select = function <T, TOut extends any>(select_fun: (x: T) => TOut): TOut[] {
    let t_list: TOut[] = [];
    for (let i = 0; i < this.length; i++) {
        const v = this[i];
        t_list.push(select_fun(v));
    }
    return t_list;
}

/**
 * 异步分离函数
 */
Array.prototype.SelectAsync = async function <T, TOut extends any>(select_fun: (x: T) => Promise<TOut>): Promise<TOut[]> {
    let t_list: TOut[] = [];
    for (let i = 0; i < this.length; i++) {
        const v = this[i];
        t_list.push(await select_fun(v));
    }
    return t_list;
}

/**
 * 两个数组合并的集合
 * @param array 
 */
Array.prototype.Union = function <T>(array: T[]): T[] {
    let tempArr = (<Array<T>>this).concat(array);
    return tempArr.Distinct();
}

/**
 * 去除重复项
 */
Array.prototype.Distinct = function <T>(): T[] {
    let tempArr: any[] = [];
    let arr = <Array<T>>this;
    arr.filter(v => tempArr.includes(v) ? false : tempArr.push(v));
    return tempArr;
}

Array.prototype.DistinctOfPro = function <T>(...keys: [keyof T]): T[] {
    let arr = this as Array<T>;
    let temp_arr: T[] = [];

    arr.forEach(v => {
        //检测是否存在
        if (temp_arr.Where((x) => {
            let is_distinct: boolean = true;
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (x[key] != v[key]) {
                    is_distinct = false;
                    break;
                }
            }
            return is_distinct;
        }).length == 0) {
            temp_arr.push(v);
        }
    });
    return temp_arr;
}



/**
 * 清空数据
 */
Array.prototype.Clear = function <T>() {
    let arr = this as Array<T>;
    arr.splice(0, arr.length);
}

/**
 * 移除数据
 */
Array.prototype.ToRemove = function <T>(...input_arr: T[] | [keyof T, any][]): void {
    let arr = this as Array<T>;
    for (let i = arr.length - 1; i >= 0; i--) {
        const v = arr[i];
        let is_find: boolean = false;
        for (let k = 0; k < input_arr.length; k++) {
            let k_v = input_arr[k];
            if (k_v instanceof Array) {
                let key: keyof T = k_v[0];
                let value: any = k_v[1];
                if (value == v[key]) {
                    is_find = true;
                    break;
                }
                else {
                    is_find = false;
                }
            }
            else {
                if (k_v == v) {
                    is_find = true;
                    break;
                }
                else {
                    is_find = false;
                }
            }
        }
        if (is_find) {
            //删除元素
            arr.splice(i, 1);
        }
    }
}

Array.prototype.ToRemoveIndexOf = function <T>(...keys: string[]) {
    let arr = this as Array<string>;
    for (let i = this.length - 1; i >= 0; i--) {
        let can_remove = false;
        for (let k = 0; k < keys.length; k++) {
            const key = keys[k];
            if (arr[i].indexOf(key) != -1) {
                can_remove = true;
                break;
            }
        }
        can_remove && arr.splice(i, 1);
    }
}

Array.prototype.ToRemoveNotIndexOf = function <T>(...keys: string[]) {
    let arr = this as Array<string>;
    for (let i = this.length - 1; i >= 0; i--) {
        let can_remove = false;
        for (let k = 0; k < keys.length; k++) {
            const key = keys[k];
            if (arr[i].indexOf(key) === -1) {
                can_remove = true;
                break;
            }
        }
        can_remove && arr.splice(i, 1);
    }
}
String.prototype.Split = function (v: string): string[] {
    let arr = (this as string).split(v);
    return arr.Where(x => Tools.IsNotNull(x));
}

/**
 * 全部替换
 */
String.prototype.ReplaceAll = function (find_str: string, replace_str: string): string {
    let new_str = "";
    for (let i = 0; i < this.length; i++) {
        const d = this[i];
        if (d != find_str) {
            new_str += d;
        }
        else {
            new_str += replace_str;
        }
    }
    return new_str;
}

String.prototype.AlertError = function () {
    Tools.AlertError(this as string);
}

String.prototype.AlertSuccess = function () {
    Tools.AlertSuccess(this as string);
}


/**
 * 转换成JSON对象
 */
String.prototype.ToJsonVO = function (): {
    flag: boolean,
    data: any
} {
    return Tools.ToJsonVO(this as string);
}

String.prototype.IsNotNull = function () {
    return Tools.IsNotNull(this as string);
};

String.prototype.IsNull = function () {
    return Tools.IsNull(this as string);
}

String.prototype.IsJson = function (): boolean {
    return Tools.IsJson(this as string);
}