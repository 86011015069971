import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        component: () => import("../views/homePage.vue"),
      },
      {
        path: '/AdministratorManagement',
        name: 'AdministratorManagement',
        component: () => import("../views/routerView/HomeManagement/HomeCollection.vue"),
      },
      {
        path: '/banner',
        name: 'banner',
        component: () => import("../views/routerView/HomeManagement/banner.vue"),
      },
      {
        path: '/premission_group',
        name: 'PremissionGroup',
        component: () => import("../views/routerView/PremissionManagement/PremissionGroup.vue"),
      },
      {
        path: '/RealNameAuthentication',
        name: 'RealNameAuthentication',
        component: () => import("../views/routerView/auditManagement/RealNameAuthentication.vue"),
      },
      {
        path: '/NFTcastingAudit',
        name: 'NFTcastingAudit',
        component: () => import("../views/routerView/auditManagement/NFTcastingAudit.vue"),
      },
      {
        path: '/WithdrawalApplication',
        name: 'WithdrawalApplication',
        component: () => import("../views/routerView/auditManagement/WithdrawalApplication.vue"),
      },
      {
        path: '/PropCreation',
        name: 'PropCreation',
        component: () => import("../views/routerView/ceateManagement/PropCreation.vue"),
      },
      {
        path: '/NFTCasting',
        name: 'NFTCasting',
        component: () => import("../views/routerView/ceateManagement/NFTCasting.vue"),
      },
      {
        path: '/blindbox',
        name: 'blindbox',
        component: () => import("../views/routerView/sale/blindbox.vue"),
      },
      {
        path: '/nft',
        name: 'nft',
        component: () => import("../views/routerView/sale/NFT.vue"),
      },
      {
        path: '/userlist',
        name: 'userlist',
        component: () => import("../views/routerView/usermanage/userlist.vue"),
      },
      {
        path: '/platform_list',
        name: "PlatformList",
        component: () => import("../views/routerView/PlatformManagement/PlatfromList.vue")
      },
      {
        path: '/FunctionManagement',
        name: "FunctionManagement",
        component: () => import("../views/routerView/FunctionManagement.vue")
      },
      {
        path: '/AnnouncementManagement',
        name: "AnnouncementManagement",
        component: () => import("../views/routerView/AnnouncementManagement.vue")
      },
      {
        path: '/synthesis',
        name: "synthesis",
        component: () => import("../views/routerView/synthesis/synthesis.vue")
      },
      {
        path: '/WithdrawalManagement',
        name: "WithdrawalManagement",
        component: () => import("../views/routerView/WithdrawalManagement.vue")
      }, {
        path: '/linglongtower',
        name: "linglongtower",
        component: () => import("../views/routerView/linglongtower/linglongtower.vue")
      },
      {
        path: '/substitution',
        name: 'substitution',
        component: () => import("../views/routerView/Substitution.vue")
      },
      {
        path: '/SMSConfiguration',
        name: 'SMSConfiguration',
        component: () => import("../views/routerView/SMSConfiguration.vue")
      },
      {
        path: '/SalesDelivery',
        name: 'SalesDelivery',
        component: () => import("../views/routerView/SalesDelivery.vue")
      },
      {
        path: '/DataManagement',
        name: 'DataManagement',
        component: () => import("../views/routerView/DataManagement.vue")
      },
      {
        path: '/nfttyle',
        name: 'nfttyle',
        component: () => import("../views/routerView/systemtools/nfttyle.vue")
      },
      {
        path: '/chainnft',
        name: 'chainnft',
        component: () => import("../views/routerView/systemtools/chainnft.vue")
      },
      {
        path: '/WenchangChain',
        name: 'WenchangChain',
        component: () => import("../views/routerView/systemtools/WenchangChain.vue")
      },
      {
        path: '/material',
        name: 'material',
        component: () => import("../views/routerView/substitution/material.vue")
      },
      {
        path: "/test",
        name: "Test",
        component: () => import("../views/routerView/Test.vue")
      },
      {
        path: "/CustomerService",
        name: "CustomerService",
        component: () => import("../views/routerView/CustomerService.vue")
      },
      {
        path: '/DeployNFT',
        name: 'DeployNFT',
        component: () => import("../views/routerView/DeployNFT.vue"),
      },
      {
        path: '/exchange',
        name: 'exchange',
        component: () => import("../views/routerView/auditManagement/exchange.vue"),
      },
      {
        path: '/DApp',
        name: 'DApp',
        component: () => import("../views/routerView/DApp.vue"),
      },
      {
        path: '/Digitalrubikcube',
        name: 'Digitalrubikcube',
        component: () => import("../views/routerView/Digitalrubikcube.vue"),
      },
      {
        path: '/CollectionLockManagement',
        name: 'CollectionLockManagement',
        component: () => import("../views/routerView/CollectionLockManagement.vue"),
      },
      {
        path: '/access_key',
        name: 'access_key',
        component: () => import("../views/routerView/AccessKey.vue"),
      },
      {
        path: '/fund_management',
        name: 'fund_management',
        component: () => import("../views/routerView/FundManagement.vue"),
      },
      {
        path: '/Recharge_record',
        name: 'Recharge_record',
        component: () => import("../views/routerView/RechargeRecord.vue"),
      },
      
      
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/Login/Login.vue"),
    meta: {
      title: '登录'
    }
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
