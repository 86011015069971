<template>
  <div class="loading_box el-loading-mask">
    <div class="el-loading-spinner">
      <svg class="circular" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none"></circle>
      </svg>
      <p class="el-loading-text" v-html="loading_message"></p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading_message: String,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.loading_box {
  z-index: 20000 !important;
}

.el-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
}
.circular {
  display: inline;
  animation: loading-rotate 2s linear infinite;
}
</style>