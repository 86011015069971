<template>
  <LoadingComponent
    :loading_message="loading_message"
    v-if="show_loading"
  ></LoadingComponent>
  <SelectPremissionComponents
    v-if="show_select_premission_window"
    :default_select_premission_list="default_select_premission_list"
    :select_premission_callback_fun="select_premission_callback_fun"
    :select_premission_multiple="select_premission_multiple"
  ></SelectPremissionComponents>
  <InputBox
    v-if="show_input_box"
    :title="input_title"
    :content="input_content"
    :call_fun="input_call_fun"
    :input_box_type="input_box_type"
    :input_max_length="input_max_length"
  ></InputBox>
  <BucketBox v-if="show_bucket_box"></BucketBox>
  <router-view />
</template>
<script>
//桶管理面板
import BucketBox from "@/components/BucketComponents.vue"; //桶管理面板
import InputBox from "@/components/InputBox.vue";
import SelectPremissionComponents from "@/components/SelectPremissionComponents.vue";
import LoadingComponent from "@/components/LoadingComponents.vue"; //Loading
import { ElMessageBox } from "element-plus";

import Global from "@/ts/Global";
import Tools from "@/ts/Tools";
import { inject, provide } from "vue";
export default {
  name: "App",
  setup() {
    provide("user_vo", Global.login_user_vo);
    provide("premission_list", Global.premission_list); //暴露权限列表
    provide("platform_vo", Global.select_platform_vo);
    const user_vo = inject("user_vo");

    return {
      user_vo,
    };
  },
  data() {
    return {
      show_loading: false, //是否显示loading
      loading_message: "Loading...", //Loaidng内容

      show_select_premission_window: false, //是否显示权限窗口
      select_premission_callback_fun: function (list) {},
      default_select_premission_list: [], //默认选择
      select_premission_multiple: false, //是否支持多选

      show_bucket_box: false, //桶窗口

      //#region 输入框
      input_title: "",
      input_content: "",
      input_box_type: 0,
      input_max_length: 0, //输入框最大长度
      input_call_fun: function (txt) {},
      show_input_box: false,
      //#endregion
    };
  },
  watch: {
    $route(to, from) {
      if (Global.select_platform_vo)
        if (Tools.IsNull(Global.select_platform_vo.guid))
          Global.login_user_vo &&
            Global.GetPlatformDataFromGuidAsync(
              Global.login_user_vo.admin_platform_guid
            );
    },
  },
  components: {
    BucketBox,
    InputBox,
    SelectPremissionComponents,
    LoadingComponent,
  },
  methods: {
    /**
     * 显示选择权限窗口
     */
    ShowSelectPremission(default_select_premission_list, callback, multiple) {
      this.select_premission_callback_fun = callback;

      this.default_select_premission_list = default_select_premission_list;
      this.select_premission_multiple = multiple;
      this.show_select_premission_window = true;
    },
    /**
     * 弹出消息框
     * @param title
     * @param content
     * @param success_fun
     * @param cancel_fun
     */
    MessageBox(title, content, success_fun, cancel_fun, option) {
      ElMessageBox.confirm(content, title, {
        confirmButtonText: option?.confirmButtonText ?? "确定",
        cancelButtonText: option?.cancelButtonText ?? "取消",
        type: "warning",
      })
        .then(success_fun)
        .catch(cancel_fun);
    },
    /**
     * 显示桶管理
     */
    ShowBucketBox() {
      this.show_bucket_box = true;
    },
    /**
     * 关闭桶管理
     */
    CloseBucketBox() {
      this.show_bucket_box = false;
    },
    /**
     * 显示全局Loading
     */
    ShowGlobalLoading(msg) {
      this.show_loading = true;
      this.loading_message = msg;
    },
    /**
     * 隐藏全局Loading
     */
    HideGlobalLoading() {
      this.show_loading = false;
    },

    ShowInputBox(
      title,
      content,
      input_box_type,
      input_call_fun,
      input_max_length
    ) {
      this.input_title = title;
      this.input_content = content;
      this.input_box_type = input_box_type;
      this.input_call_fun = input_call_fun;
      this.input_max_length = input_max_length;

      this.show_input_box = true;
    },
  },
  created() {
    Global.app = this;
  },
  mounted() {},
};
</script>

<style>
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
#app {
  width: auto;
  color: #333;
  width: 100%;
  height: 100%;
}

img {
  object-fit: cover;
}

.is-message-box {
  z-index: 3200 !important;
}
/* 灰色 */
.to_gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.success_btn {
  background-color: #42b983;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.delete_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.error_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.warning_btn {
  background-color: #ffb800;
  color: white;
  border: 0px;
  border-radius: 0px;
}
</style>
