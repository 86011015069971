import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/theme-chalk/index.css';
import { reactive, provide } from 'vue';
import Global from '@/ts/Global';
import Tools from '@/ts/Tools';
import PremissionComponent from "@/components/PremissionComponent.vue";
import IM_User from '@/ts/vo/IM_User';
// require("@/ts/SuperExt");
import "@/ts/SuperExt";
import { VueUeditorWrap } from 'vue-ueditor-wrap';
import * as echarts from 'echarts'

Global.Echarts = echarts;
//#region 全局双向绑定类
Global.login_user_vo = reactive({}) as IM_User;
//#endregion
Global.CheckIsLoginAsync((u) => {

  let app = createApp(App)
  app.use(router);
  app.component("premission",PremissionComponent);
  app.use(ElementPlus, {
    locale: zhCn,
  })
  app.use(router).use(ElementPlus).use(VueUeditorWrap);
  app.mount('#app')
});




