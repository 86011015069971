<template>
  <div class="input_box_body">
    <div class="input_box_content">
      <my-image
        class="close_btn"
        :src="require('@/assets/images/x.png')"
        @click="CloseMe"
      />
      <span v-if="title" class="title">{{ title }}</span>
      <textarea
        v-if="input_box_type == 0"
        class="input_content"
        v-model="content"
        :placeholder="title == null ? '请输入内容' : title"
        :maxlength="input_max_length > 0 ? input_max_length : ''"
      ></textarea>

      <el-input
        type="text"
        class="input_content"
        v-if="input_box_type == 1"
        v-model="content"
        :maxlength="input_max_length > 0 ? input_max_length : ''"
        :placeholder="title == null ? '请输入内容' : title"
      />

      <el-input
        type="number"
        class="input_content"
        v-if="input_box_type == 6"
        v-model="content"
        :placeholder="title == null ? '请输入数字' : title"
      />

      <div class="upload_file_box" v-if="input_box_type == 2">
        <img
          ref="image_input"
          src="image/upload_image_icon.png"
          @click="UploadImageAsync()"
        />
      </div>

      <el-input
        type="password"
        class="input_content"
        v-if="input_box_type == 3"
        v-model="content"
        :maxlength="input_max_length > 0 ? input_max_length : ''"
        :placeholder="title == null ? '请输入密码' : title"
      />

      <a-date-picker
        inputReadOnly
        class="bottom_10 date_time_input"
        :default-value="content"
        v-if="input_box_type == 4"
        show-time
        :placeholder="title == null ? '请输入时间+日期' : title"
        @change="onDateTimeChange"
      />

      <a-date-picker
        inputReadOnly
        class="bottom_10 date_time_input"
        :default-value="content"
        format="YYYY/MM/DD"
        v-if="input_box_type == 5"
        :placeholder="title == null ? '请输入时间+日期' : title"
        @change="onDateChange"
      />
      <div class="row">
        <el-button @click="cancelClose">取消</el-button>
        <el-button type="primary" @click="ToSave">保存</el-button>
      </div>
    </div>
  </div>
  <div class="bottom">
    <pagination @currentChange="currentChange" />
  </div>
</template>
  <script>
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";
export default {
  props: {
    input_box_type: 0,
    title: String,
    content: String,
    input_max_length: Number, //输入框最大长度
    call_fun: function (txt) {},
  },
  data() {
    return {
      date_content: "",
    };
  },
  mounted() {
    this.date_content = "";
    if (this.input_box_type == 2) {
      if (Tools.IsNotNull(this.content)) {
        let img = new Image();
        img.onload = (e) => {
          this.$refs["image_input"].src = this.content;
        };
        img.onerror = () => {};
        img.src = this.content;
      }
    }
  },
  methods: {
    onDateChange(date, dateString) {
      console.log(date, dateString);
      this.date_content = dateString;
    },
    onDateTimeChange(date, dateString) {
      // this.content = dateString;
      this.date_content = dateString;
    },
    async UploadFileAsync(event) {
      var files = event.target.files;
      if (files.length > 0) {
        let res = await Tools.UploadFileAsync("UploadFile", {}, files);
        if (Tools.CheckHtmlIsOK(res)) {
          let v = res.data[0];
          this.call_fun && this.call_fun(v.FileURL);
          this.CloseMe();
        }
      }
    },
    CloseMe() {
      this.$parent.show_input_box = false;
    },
    async UploadImageAsync() {
      let open_image_item = await Global.SelectPhotosAsync();
      if (!open_image_item.flag) {
        Tools.AlertError(open_image_item.error_message);
      } else {
        this.call_fun && this.call_fun(open_image_item.file_url);
      }
      this.CloseMe();
    },
    ToSave() {
      if (this.call_fun) {
        switch (this.input_box_type) {
          case 4:
          case 5:
            this.call_fun(this.date_content);
            break;
          default:
            this.call_fun(this.content);
            break;
        }
      }
      this.CloseMe();
    },
    cancelClose() {
      this.CloseMe();
    },
  },
};
</script>
  <style scoped lang="scss">
//#region 样式变量
$home_bgc_class: var(--home_bgc_class, #070d0d);
$c303538_ffffff: var(--c303538_ffffff, #303538);
$bgc_class: var(--bgc_class, #191f24);
$line_: var(--line, "#303339");
$border_: var(--border, "#6D7278");
$ffffff_324460: var(--ffffff_324460, #ffffff);
$c303339_ffffff: var(--c303339_ffffff, #303339);
$c191F24_ffffff: var(--c191F24_ffffff, #191f24);
$c2A2E2F_ffffff: var(--c2A2E2F_ffffff, #2a2e2f);
$fffffe_324460: var(--fffffe_324460, #fffffe);
$c848484_324460: var(--c848484_324460, #848484);
$c02cdb0_ffffff: var(--c02cdb0_ffffff, #02cdb0);
$ffffff_c02cdb0: var(--ffffff_c02cdb0, #ffffff);
$ffffff_c6D7278: var(--ffffff_c6D7278, #ffffff);
$c6D7278_A8ADB4: var(--ffffff_c6D7278, #6d7278);
$C2C2C3_848484: var(--C2C2C3_848484, #c2c2c3);
$ffffff_303339: var(--C2C2C3_848484, #ffffff);
$c24619387_FC5925: var(--c24619387_FC5925, rgb(246, 193, 87));
$c212323_ffffff: var(--c212323_ffffff, #212323);
$f6fafe_434343: var(--f6fafe_434343, #f6fafe);
$c181818_f6fafe: var(--c181818_f6fafe, #181818);
$c3B3F40_E9EEF5: var(--c3B3F40_E9EEF5, #3b3f40);
$c2A2E2F_F6FAFE: var(--c2A2E2F_F6FAFE, #2a2e2f);
$c191F24_1BCDAF: var(--c191F24_1BCDAF, #191f24);
$ffffff_1BCDAF: var(--ffffff_1BCDAF, #ffffff);
$c000000_FFFFFF: var(--c000000_FFFFFF, #000000);
$c000000_F1F1F1: var(--c000000_F1F1F1, #000000);
$c000000_4B4C4D: var(--c000000_4B4C4D, #000000);
$box_bg: var(--box_bg, #2a2e2f);
$c000000_ACB5BB: var(--c000000_ACB5BB, #000000);
$c303339_F5F6F7: var(--c303339_F5F6F7, #303339);
$c02CDB0_324460: var(--c02CDB0_324460, #02cdb0);
$ffffff_F6FAFE: var(--ffffff_F6FAFE, #ffffff);
$FEFFFF_324460: var(--FEFFFF_324460, #feffff);
$c191f24_FEFFFF: var(--c191f24_FEFFFF, #191f24);
$c2A2E2F_FEFFFF: var(--c2A2E2F_FEFFFF, #2a2e2f);
$ffffff_0091FF: var(--ffffff_0091FF, #ffffff);
$c000000_ffffff: var(--c000000_ffffff, #000000);
$c191F24_F6FAFE: var(--c191F24_F6FAFE, #191f24);
$c1B1C1E_FFFFFF: var(--c1B1C1E_FFFFFF, #1b1c1e);
$FFFFFF_c040506: var(--FFFFFF_c040506, #040506);
$c1B1C1E_F0F1F5: var(--c1B1C1E_F0F1F5, #1b1c1e);
$c000000_F0F1F5: var(--c000000_F0F1F5, #000000);
$c2B2C2E_E1E1E2: var(--c2B2C2E_E1E1E2, #2b2c2e);
$c292929_ffffff: var(--c292929_ffffff, #292929);
$C30353A_FFFFFF: var(--C30353A_FFFFFF, #ffffff);
$c191F24_E1E2E6: var(--c191F24_E1E2E6, #191f24);
$c2b2c2e_f2f2f2: var(--c2b2c2e_f2f2f2, #2b2c2e);
//#endregion

.input_box_body {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: center;
  background-color: var(--el-overlay-color-lighter);
  width: 100%;
  height: 100%;
  z-index: 5000;
}

.input_box_content {
  position: relative;
  width: 20%;
  background-color: white;
  padding: 10px 20px 20px 20px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 5%;
}

.input_content {
  border: 0px;
  border-radius: 0px;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  line-height: 36px;
  font-size: 16px;
  box-sizing: border-box;
  color: $FFFFFF_c040506;
}

.close_btn {
  // width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.success_btn {
  width: 100%;
  height: 30px;
  font-size: 20px;
  /* font-weight: bold; */
  border: 0px;
  border-radius: 0px;
}

.title {
  color: $FFFFFF_c040506;
  margin-bottom: 10px;
  font-size: 15px;
  /* font-weight: bold; */
}
.textarea {
  height: 250px;
}
</style>
  
  <style>
/* .input_content {
    color: black !important;
  } */
.ant-calendar-picker-container {
  z-index: 1000000 !important;
}
.bottom_10 {
  margin-bottom: 10px;
}
.date_time_input {
  width: 100%;
}

.upload_file_box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_file_box > img {
  margin-bottom: 10px;
  width: 100%;
  display: inline-block;
  height: 80px;
  width: 80px;
}
.row {
  display: flex;
}
</style>